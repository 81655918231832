import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  CardContent,
  Tooltip as MuiTooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import useCampaigns from "../../../models/campaigns/useCampaigns";
import FormTextInput from "../../../components/TextInput/Form";
import { buildValidationSchema } from "./validations";
import PaymentInfoModal from "../../../containers/PaymentInfoModal";
import { InfoOutlined } from "@mui/icons-material";

import "./styles.scss";

const CampaignFile = ({ isCreation }) => {
  const { id: campaignId } = useParams();
  const navigate = useNavigate();
  const {
    campaign,
    fetchCampaign,
    updateCampaign,
    createCampaign,
    setCampaign,
  } = useCampaigns();
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(buildValidationSchema()),
    defaultValues: {
      name: "",
      influencer_prize: "",
      ig_account: "",
      bottle_requirement: "",
      glass_requirement: "",
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isValid, isDirty, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      if (isCreation) {
        await createCampaign({
          ...data,
          ig_account: data.ig_account.replace("@", "").toLowerCase(),
        });
        navigate("../");
        toast.success("Campaña creada correctamente.");
      } else {
        await updateCampaign(campaignId, {
          ...data,
          ig_account: data.ig_account.replace("@", "").toLowerCase(),
        });
        toast.success("Campaña actualizada correctamente.");
      }
    } catch (e) {
      if (e.status === 402) {
        setShowPaymentModal(true);
      } else {
        toast.error("Ups, ha ocurrido un error.");
      }
    }
  };

  useEffect(() => {
    campaign &&
      reset({
        name: campaign.name,
        influencer_prize: campaign.influencer_prize,
        ig_account: campaign.ig_account,
        bottle_requirement: campaign.bottle_requirement,
        glass_requirement: campaign.glass_requirement,
      });
  }, [campaign]);

  useEffect(() => {
    !isCreation && fetchCampaign(campaignId);
    return () => {
      setCampaign({});
    };
  }, []);

  return (
    campaign && (
      <div className="CampaignFile">
        <FormProvider {...methods} autoComplete={false}>
          <div className="CampaignFile__header">
            <h1>Detalle de campaña</h1>
            <div className="CampaignFile__form__actions">
              <Button
                variant="outlined"
                onClick={() => reset(campaign)}
                disabled={!isDirty}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid || !isDirty || isSubmitting}
              >
                Guardar
              </Button>
            </div>
          </div>
          <div className="CampaignFile__card">
            <div className="CampaignFile__card__label">
              Datos de campaña
              <MuiTooltip title="Configura la campaña de como quieres promocionar tu marca">
                <InfoOutlined fontSize="small" />
              </MuiTooltip>
            </div>
            <Card elevation={0}>
              <CardContent>
                <div className="CampaignFile__form__inputs">
                  <div>
                    <div className="CampaignFile__form__label">
                      Nombre de la campaña
                      <MuiTooltip title="Utiliza un nombre único para poder distinguir entre campañas">
                        <InfoOutlined fontSize="small" />
                      </MuiTooltip>
                    </div>
                    <FormTextInput
                      control={control}
                      name="name"
                      placeholder="Ramón Bilbao - copa"
                      required={true}
                    />
                  </div>
                  <div>
                    <div className="CampaignFile__form__label">
                      Premio por story{" "}
                      <MuiTooltip title="Este es el premio que designa el restaurante a sus clientes que suben stories a cambio de ese premio. Al escribir texto en esta casilla se escribirá también en el QR de acceso. Lo podrán ver escrito tus clientes en los QR que les exhibas.">
                        <InfoOutlined fontSize="small" />
                      </MuiTooltip>
                    </div>
                    <FormTextInput
                      control={control}
                      name="influencer_prize"
                      placeholder="Ej, una caña"
                      required={true}
                    />
                  </div>
                  <div>
                    <div className="CampaignFile__form__label">
                      Cuenta de Instagram{" "}
                      <MuiTooltip title="Si utilizas este campo, se validará que mencionan a esta cuenta de Instagram">
                        <InfoOutlined fontSize="small" />
                      </MuiTooltip>
                    </div>
                    <FormTextInput
                      control={control}
                      name="ig_account"
                      placeholder="Cuenta de Instagram"
                    />
                  </div>
                  <div>
                    <div className="CampaignFile__form__label">
                      Validar botella
                      <MuiTooltip title="Si utilizas este campo, se validará que aparece una botella de la marca">
                        <InfoOutlined fontSize="small" />
                      </MuiTooltip>
                    </div>
                    <FormTextInput
                      control={control}
                      name="bottle_requirement"
                      placeholder="Ej, Ramón bilbao"
                    />
                  </div>
                  <div>
                    <div className="CampaignFile__form__label">
                      Validar copa
                      <MuiTooltip title="Si utilizas este campo, se validará que aparece una copa de lo que quieras">
                        <InfoOutlined fontSize="small" />
                      </MuiTooltip>
                    </div>
                    <FormTextInput
                      control={control}
                      name="glass_requirement"
                      placeholder="Ej, vino"
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </FormProvider>
        {showPaymentModal && (
          <PaymentInfoModal
            onClose={() => setShowPaymentModal(false)}
            onPaymentSucceed={handleSubmit(onSubmit)}
          />
        )}
      </div>
    )
  );
};

export default CampaignFile;
