import { useSelector, useDispatch } from "react-redux";
import useFetch from "../../utils/useFetch";
import { setList, setDetail, setAnalytics } from "./store";

const useCampaigns = () => {
  const { list, detail, analytics } = useSelector((state) => state.campaigns);
  const dispatch = useDispatch();
  const { get, post, put } = useFetch();

  const endpoint = "brand-campaigns";

  const fetchList = async () => {
    const response = await get({
      endpoint,
      isPrivate: true,
    });
    response && dispatch(setList(response));
  };

  const fetchDetail = async (id) => {
    const response = await get({
      endpoint: `${endpoint}/${id}`,
      isPrivate: true,
    });
    dispatch(setDetail(response));
  };

  const updateDetail = async (id, data) => {
    const response = await put({
      endpoint,
      id,
      body: data,
    });
    response && dispatch(setDetail(response));
  };

  const fetchAnalytics = async (campaign, restaurant) => {
    const response = await get({
      endpoint: `brands/statistics`,
      params: { campaign, restaurant },
      isPrivate: true,
    });
    response && dispatch(setAnalytics(response));
  };

  const createCampaign = async (data) => {
    return await post({
      endpoint,
      body: data,
    });
  };

  const activateCampaign = async (id) => {
    return await post({
      endpoint: `${endpoint}/${id}/activate`,
    });
  };

  const deactivateCampaign = async (id) => {
    return await post({
      endpoint: `${endpoint}/${id}/deactivate`,
    });
  };

  return {
    campaignList: list,
    campaign: detail,
    campaignAnalytics: analytics,
    fetchCampaignList: fetchList,
    fetchCampaign: fetchDetail,
    setCampaign: (campaign) => dispatch(setDetail(campaign)),
    updateCampaign: updateDetail,
    fetchAnalytics,
    createCampaign,
    activateCampaign,
    deactivateCampaign,
  };
};

export default useCampaigns;
