import { Button, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "../../components/Table";

import useAuth from "../../models/auth/useAuth";
import useWorkers from "../../models/workers/useWorkers";
import SwitchInput from "../../components/Switch";
import InvitationModal from "./InvitationModal";

import "./styles.scss";

const WorkersList = () => {
  const {
    user: { brand_worker },
  } = useAuth();
  const { workers, fetchWorkers, deleteWorker } = useWorkers();
  const [showInvitationModal, setShowInvitationModal] = useState(false);

  const handleDeleteWorker = async (id) => {
    try {
      await deleteWorker(id);
      toast.success("Trabajador eliminado correctamente.");
    } catch (e) {
      toast.error("UPS! Ha ocurrido un error.");
    }
  };

  const tableColumns = [
    { headerName: "Email", field: "email", flex: 2, sortable: false },
    {
      headerName: "",
      type: "actions",
      field: " ",
      sortable: false,
      renderCell: ({ row }) => (
        <IconButton
          onClick={() => handleDeleteWorker(row.id)}
          disabled={brand_worker.id == row.id}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    fetchWorkers();
  }, [brand_worker]);

  return (
    <>
      <div className="WorkersList">
        <div className="WorkersList__header">
          <h1>Listado de trabajadores</h1>
          <Button
            variant="contained"
            onClick={() => setShowInvitationModal(true)}
          >
            Invitar trabajador
          </Button>
        </div>
        <Table columns={tableColumns} data={workers} hidePagination />
      </div>
      <InvitationModal
        isOpen={showInvitationModal}
        onClose={() => setShowInvitationModal(false)}
      />
    </>
  );
};

export default WorkersList;
