import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import logo from "../../assets/logo_blue.svg";
import phone from "./images/phone.png";
import card1 from "./images/card1.png";
import card2 from "./images/card2.png";
import card3 from "./images/card3.png";
import card4 from "./images/card4.png";
import card5 from "./images/card5.png";

import "./styles.scss";

const Home = () => {
  return (
    <div className="Home">
      <div className="Home__banner">
        <div className="Home__banner__title">
          Bienvenidos a{" "}
          <div className="Home__banner__title__logo">
            <img src={logo} />
          </div>
        </div>
        <div className="Home__banner__subtitle">Pon de moda tus marcas</div>
        <div className="Home__banner__button">
          <Link to="create-account">
            <Button variant="contained">Crea tu cuenta gratuita</Button>
          </Link>
        </div>
        <div className="Home__banner__demo">
          <a href="mailto:hello@wubalu.com">
            Envianos un email para solicitar una demo
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
