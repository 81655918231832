import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import FormTextInput from "../../../components/TextInput/Form";
import useAuth from "../../../models/auth/useAuth";
import useAccounts from "../../../models/accounts/useAccounts";
import { buildValidationSchema } from "./validations";
import { useNavigate } from "react-router-dom";

const CreateAccountStep2 = () => {
  const { createAccount } = useAccounts();
  const { me } = useAuth();
  const navigate = useNavigate();

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(buildValidationSchema()),
    defaultValues: {
      name: "",
      password: "",
      repeatPassword: "",
    },
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await createAccount(data);
      await me();
      navigate("/admin/campaigns");
      toast.success("Cuenta creada correctamente.");
    } catch (e) {
      toast.error("Ups, ha ocurrido un error.");
    }
  };

  return (
    <div className="CreateAccount__step">
      <div className="CreateAccount__step__title">
        Y para finalizar necesitamos el <span>nombre de tu cuenta.</span>
      </div>
      <div className="CreateAccount__step__subtitle">
        *Este nombre no es visible para los clientes.
      </div>
      <div className="CreateAccount__step__form">
        <FormProvider {...methods} autoComplete={false}>
          <FormTextInput
            control={control}
            name="name"
            required={true}
            label="Nombre de la cuenta"
          />
          <Button
            className="CreateAccount__step__submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || !isDirty || isSubmitting}
          >
            Crear cuenta
          </Button>
        </FormProvider>
      </div>
    </div>
  );
};

export default CreateAccountStep2;
