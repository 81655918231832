import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";
import { useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { createTheme, ThemeProvider } from "@mui/material";

import useAuth from "./models/auth/useAuth";
import AuthRoute from "./containers/AuthRoute";
import AdminLayout from "./containers/AdminLayout";
import Dashboard from "./pages/Dashboard";
import AdminRoute from "./containers/AdminRoute";
import CampaignFile from "./pages/Campaigns/Detail";
import WorkersList from "./pages/Workers";
import Invitations from "./pages/Invitations";
import StoryList from "./pages/Stories";
import Home from "./pages/Home";
import Campaigns from "./pages/Campaigns";
import DeactivateAccount from "./pages/DeactivateAccount";
import CreateAccount from "./pages/CreateAccount";
import Billing from "./pages/Billing";

import "./styles.scss";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="" element={<AdminLayout />}>
      <Route path="/" element={<Home />} />
      <Route path="admin/*" element={<AuthRoute />}>
        <Route path="" element={<Navigate to="/admin/campaigns" />} />
        <Route
          path="campaigns"
          element={
            <AdminRoute>
              <Campaigns />
            </AdminRoute>
          }
        />
        <Route
          path="campaigns/create"
          element={
            <AdminRoute>
              <CampaignFile isCreation={true} />
            </AdminRoute>
          }
        />
        <Route
          path="campaigns/:id"
          element={
            <AdminRoute>
              <CampaignFile />
            </AdminRoute>
          }
        />
        <Route
          path="dashboard"
          element={
            <AdminRoute>
              <Dashboard />
            </AdminRoute>
          }
        />
        <Route
          path="workers"
          element={
            <AdminRoute>
              <WorkersList />
            </AdminRoute>
          }
        />
        <Route
          path="stories"
          element={
            <AdminRoute>
              <StoryList />
            </AdminRoute>
          }
        />
        <Route path="invitations" element={<Invitations />} />
        <Route path="delete-account" element={<DeactivateAccount />} />
        <Route
          path="billing"
          element={
            <AdminRoute>
              <Billing />
            </AdminRoute>
          }
        />
      </Route>
      <Route path="create-account" element={<CreateAccount />} />
    </Route>
  )
);

function App() {
  const { isAuthenticated, me } = useAuth();
  useEffect(() => {
    if (!isAuthenticated) me();
    const analyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

    if (analyticsId) {
      const script = document.createElement("script");
      script.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
      script.async = true;
      document.body.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", analyticsId);
    }
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#6854ff",
      },
    },
  });

  return (
    <GoogleOAuthProvider clientId="799471198822-i04f0ncrnkavdpd6fhv3h3ge0jp7v40o.apps.googleusercontent.com">
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
