import { useDispatch, useSelector } from "react-redux";

import {
  showLoader,
  hideLoader,
  showConfirmation,
  hideConfirmation,
} from "./store";

const useUi = () => {
  const { loaderCount, confirmation } = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  return {
    isLoaderVisible: loaderCount > 0,
    confirmation,
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
    showConfirmation: (data) => dispatch(showConfirmation(data)),
    hideConfirmation: () => dispatch(hideConfirmation()),
  };
};

export default useUi;
