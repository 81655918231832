import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import useCampaigns from "../../models/campaigns/useCampaigns";
import Table from "../../components/Table";
import SwitchInput from "../../components/Switch";
import CampaignsEmptyState from "./EmptyState";
import { toast } from "react-toastify";
import useUi from "../../models/ui/useUi";

import "./styles.scss";

const Campaigns = () => {
  const navigate = useNavigate();
  const {
    campaignList,
    fetchCampaignList,
    activateCampaign,
    deactivateCampaign,
  } = useCampaigns();
  const { showConfirmation } = useUi();

  const handleSetIsActive = async (id, isActive) => {
    try {
      if (isActive) {
        await activateCampaign(id);
        toast.success("Campaña activada.");
      } else {
        await deactivateCampaign(id);
        toast.success("Campaña desactivada.");
      }
      fetchCampaignList();
    } catch (e) {
      toast.error("UPS! Ha ocurrido un error.");
    }
  };

  const handleConfirmSetInactive = (id) => {
    showConfirmation({
      title: "¿Seguro que quieres desactivar esta campaña?",
      text: "Si desactivas esta campaña, desaparecerá de todos los restaurantes en los que está asociada.",
      onConfirm: () => handleSetIsActive(id, false),
    });
  };

  const tableColumns = [
    { headerName: "Nombre", field: "name", flex: 3, sortable: false },
    {
      headerName: "Nº restaurantes asociados",
      field: "restaurants_count",
      flex: 1,
      sortable: false,
    },
    {
      headerName: "Activada",
      field: "is_active",
      sortable: false,
      renderCell: ({ row }) => (
        <SwitchInput
          checked={row.is_active}
          onChange={(_, isActive) =>
            !isActive
              ? handleConfirmSetInactive(row.id)
              : handleSetIsActive(row.id, true)
          }
        />
      ),
    },
    {
      headerName: "",
      type: "actions",
      field: " ",
      sortable: false,
      renderCell: ({ row }) => (
        <IconButton onClick={() => navigate(`./${row.id}`)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    fetchCampaignList();
  }, []);

  return (
    <>
      <div className="CampaignList">
        <div className="CampaignList__header">
          <h1>Listado de campañas</h1>
          {campaignList.length > 0 && (
            <Link to="./create">
              <Button variant="contained">Añadir Campaña</Button>
            </Link>
          )}
        </div>
        {campaignList.length > 0 ? (
          <Table columns={tableColumns} data={campaignList} hidePagination />
        ) : (
          <CampaignsEmptyState />
        )}
      </div>
    </>
  );
};

export default Campaigns;
