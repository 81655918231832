import { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import GoogleButton from "../../components/GoogleButton";
import FormTextInput from "../../components/TextInput/Form";
import useAuth from "../../models/auth/useAuth";
import { buildValidationSchema } from "./validations";
import { Button } from "@mui/material";

import "./styles.scss";

const AuthModal = () => {
  const { convertToken, signup, login } = useAuth();
  const [view, setView] = useState("login");

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(buildValidationSchema(view)),
    defaultValues: {
      name: "",
      password: "",
      repeatPassword: "",
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty, isSubmitting },
  } = methods;

  const responseGoogle = (response) => {
    convertToken({
      token: response.access_token,
      backend: "google-oauth2",
    });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: (error) => console.log("Login Failed:", error),
  });

  const onSubmit = ({ email, password }) => {
    if (view === "signup") {
      signup({
        email,
        password,
      });
    } else {
      login({
        email,
        password,
      });
    }
  };

  return (
    <div className="AuthModal">
      <div className="AuthModal__content">
        <FormProvider {...methods} autoComplete={false}>
          <div className="AuthModal__main">
            <h1 className="AuthModal__title title">
              Para empezar, dinos quién eres.
            </h1>
            <div className="AuthModal__social-auth subtitle">Acceso rápido</div>
            <GoogleButton onClick={googleLogin} text="Continuar con Google" />
            <div className="AuthModal__email">
              <div className="AuthModal__email__title subtitle">
                o {view === "signup" ? "regístrate" : "continúa"} con tu email
              </div>
              <FormTextInput
                control={control}
                name="email"
                placeholder="Tu email"
                required={true}
              />
              <FormTextInput
                control={control}
                name="password"
                type="password"
                placeholder="Contraseña"
                required={true}
              />
              {view === "signup" && (
                <FormTextInput
                  control={control}
                  type="password"
                  name="repeatPassword"
                  placeholder="Repetir contraseña"
                  required={true}
                />
              )}
            </div>
          </div>
          <div>
            <Button
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid}
            >
              {view === "signup" ? "Registrarme" : "Iniciar sesión"}
            </Button>
            {view === "signup" ? (
              <div
                className="AuthModal__change-view"
                onClick={() => setView("login")}
              >
                Ya tengo cuenta
              </div>
            ) : (
              <div
                className="AuthModal__change-view"
                onClick={() => setView("signup")}
              >
                No tengo cuenta, registrarme
              </div>
            )}
          </div>
        </FormProvider>
      </div>
    </div>
  );
};

export default AuthModal;
