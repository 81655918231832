import { createSlice } from "@reduxjs/toolkit";

const initialConfirmation = {
  isVisible: false,
  title: "",
  text: "",
  onConfirm: null,
  onCancel: null,
};

const initialState = {
  loaderCount: 0,
  confirmation: initialConfirmation,
};

export const slice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    showLoader: (state) => {
      state.loaderCount += 1;
    },
    hideLoader: (state) => {
      state.loaderCount -= 1;
    },
    showConfirmation: (state, action) => {
      state.confirmation = { ...action.payload, isVisible: true };
    },
    hideConfirmation: (state) => {
      state.confirmation = initialConfirmation;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showLoader, hideLoader, showConfirmation, hideConfirmation } =
  slice.actions;

export default slice.reducer;
