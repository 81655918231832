import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useUi from "../../models/ui/useUi";

const ConfirmationModal = () => {
  const {
    confirmation: { title, text, onConfirm, onCancel, isVisible },
    hideConfirmation,
  } = useUi();

  const handleCancel = () => {
    onCancel && onCancel();
    hideConfirmation();
  };

  const handleConfirm = async () => {
    onConfirm && (await onConfirm());
    hideConfirmation();
  };

  return (
    <Dialog
      open={isVisible}
      onClose={hideConfirmation}
      className="ConfirmationModal"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="outlined">
          Cancelar
        </Button>
        <Button type="submit" variant="contained" onClick={handleConfirm}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
