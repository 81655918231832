import { Link, useNavigate } from "react-router-dom";
import { useMemo } from "react";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import menuItems from "./menuItems";
import publicMenu from "./publicMenu";
import useAuth from "../../models/auth/useAuth";
import LogoImg from "../../assets/logo_white.svg";

import "./styles.scss";

const Header = () => {
  const navigate = useNavigate();
  const {
    user: { email, username, brand_worker },
    isAuthenticated,
    logout,
  } = useAuth();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const filteredMenuItems = useMemo(() => {
    if (!brand_worker || !isAuthenticated) return publicMenu;
    if (brand_worker && !brand_worker.is_admin)
      return menuItems.filter((item) => item.worker);
    return menuItems;
  }, [brand_worker]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNavigate = (path) => {
    navigate(path);
    setAnchorElNav(null);
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" className="Header">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={() => handleNavigate("/")}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img className="Header__logo" src={LogoImg} />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {filteredMenuItems.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={() => handleNavigate(page.path)}
                >
                  <Typography sx={{ fontFamily: "dm sans" }} textAlign="center">
                    {page.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            onClick={() => handleNavigate("/")}
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img className="Header__logo" src={LogoImg} />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {filteredMenuItems.map((page) => (
              <Button
                key={page.name}
                onClick={() => handleNavigate(page.path)}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                <Typography sx={{ fontFamily: "dm sans" }}>
                  {page.name}
                </Typography>
              </Button>
            ))}
          </Box>
          {isAuthenticated ? (
            <>
              <Box sx={{ marginRight: 2, display: { xs: "none", md: "flex" } }}>
                <Typography sx={{ fontFamily: "dm sans" }}>{email}</Typography>
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Menu usuario">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={username} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {/* {brand_worker && brand_worker.is_admin && (
                    <MenuItem onClick={() => handleNavigate("/admin/billing")}>
                      <Typography
                        sx={{ fontFamily: "dm sans" }}
                        textAlign="center"
                      >
                        Facturación
                      </Typography>
                    </MenuItem>
                  )} */}
                  <MenuItem onClick={logout}>
                    <Typography
                      sx={{ fontFamily: "dm sans" }}
                      textAlign="center"
                    >
                      Cerrar sesión
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </>
          ) : (
            <Box>
              <Link to="/admin">
                <Button variant="contained" className="Header__login">
                  Login
                </Button>
              </Link>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
