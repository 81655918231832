import { useEffect, useMemo, useState } from "react";
import { Card, CardContent, Pagination } from "@mui/material";
import classNames from "classnames";
import { format } from "date-fns";

import useStories from "../../models/stories/useStories";
import AutocompleteInput from "../../components/Autocomplete";
import useCampaigns from "../../models/campaigns/useCampaigns";

import "./styles.scss";

const StoryCard = ({ story }) => {
  const [showDetail, setShowDetail] = useState(false);

  const getStatusLabel = (status) => {
    switch (status) {
      case "CREATED":
        return "Pendiente";
      case "REJECTED":
        return "Rechazada";
      case "VALIDATED":
        return "Premiada";
      case "AI_VALIDATED":
        return "Validada";
    }
  };

  return (
    <Card
      elevation={0}
      className="StoryList__item"
      onClick={() => setShowDetail(!showDetail)}
    >
      <CardContent>
        <div
          className={classNames(
            "StoryList__item__label",
            story.status.toLowerCase()
          )}
        >
          {getStatusLabel(story.status)}
        </div>
        <div className="StoryList__item__image">
          <img src={story.image} />
        </div>
        {showDetail && (
          <div className="StoryList__item__detail">
            <div>
              <div className="StoryList__item__detail__label">Restaurante</div>
              <div className="StoryList__item__detail__value">
                {story.restaurant_name}
              </div>
            </div>
            <div>
              <div className="StoryList__item__detail__label">
                Cuenta de Instagram
              </div>
              <div className="StoryList__item__detail__value">
                <a
                  href={`https://www.instagram.com/${story.ig_account}`}
                  target="_blank"
                >
                  @{story.ig_account}
                </a>
              </div>
            </div>
            <div>
              <div className="StoryList__item__detail__label">
                Fecha de creación
              </div>
              <div className="StoryList__item__detail__value">
                {format(new Date(story.created_at), "dd/MM/yyyy hh:mm:ss")}
              </div>
            </div>
            {story.status !== "CREATED" && (
              <>
                <div>
                  <div className="StoryList__item__detail__label">Campaña</div>
                  <div className="StoryList__item__detail__value">
                    {story.campaign_name}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

const StoryList = () => {
  const { storyList, fetchStories } = useStories();
  const { campaignList, fetchCampaignList } = useCampaigns();
  const [status, setStatus] = useState("VALIDATED");
  const [campaign, setCampaign] = useState({ label: "Todos", value: "" });

  const options = [
    {
      value: "VALIDATED",
      label: "Premiadas",
    },
    {
      value: "AI_VALIDATED",
      label: "Validadas",
    },
    {
      value: "REJECTED",
      label: "Rechazadas",
    },
    {
      value: "",
      label: "Todas",
    },
  ];

  const campaignOptions = useMemo(() => {
    return [
      { label: "Todos", value: "" },
      ...(campaignList || []).map((item) => ({
        label: item.name,
        value: item.id,
      })),
    ];
  }, [campaignList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [storyList]);

  useEffect(() => {
    fetchStories({ page: 1, status, campaign: campaign.value });
  }, [status, campaign]);

  useEffect(() => {
    fetchCampaignList();
  }, []);

  return (
    <div className="StoryList">
      <div className="StoryList__header">
        <h1>Listado de stories</h1>
        <div className="StoryList__filters">
          <div className="StoryList__select-campaign">
            <AutocompleteInput
              value={campaign}
              options={campaignOptions}
              onChange={setCampaign}
            />
          </div>
          <div className="StoryList__status">
            {options.map((item) => (
              <div
                key={item.value}
                className={classNames("StoryList__status__item", {
                  active: status == item.value,
                })}
                onClick={() => setStatus(item.value)}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="StoryList__list">
        {(storyList.items || []).map((item) => (
          <StoryCard key={item.id} story={item} />
        ))}
      </div>
      {Math.ceil(storyList.count / 12) > 1 && (
        <Pagination
          color="primary"
          count={Math.ceil(storyList.count / 12)}
          page={storyList.page}
          onChange={(_, page) => fetchStories({ page, status })}
        />
      )}
    </div>
  );
};

export default StoryList;
