import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { buildValidationSchema } from "./validations";
import FormTextInput from "../../../components/TextInput/Form";
import useAccounts from "../../../models/accounts/useAccounts";

import "./styles.scss";

const CompanyInfo = ({ onSubmitSuccess }) => {
  const { updateCompanyInfo } = useAccounts();
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(buildValidationSchema()),
    defaultValues: {
      nif: "",
      company_name: "",
      street: "",
      number: "",
      floor: "",
      apartment: "",
      city: "",
      zip_code: "",
      province: "",
      country: "ES",
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isValid, isDirty, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await updateCompanyInfo(data);
      toast.success("Datos de facturación añadidos.");
      onSubmitSuccess();
    } catch (e) {
      toast.error("Ups, ha ocurrido un error.");
    }
  };

  return (
    <div className="CompanyInfo">
      <div className="PaymentInfoModal__title">Datos de facturación</div>
      <div className="PaymentInfoModal__subtitle">
        Necesitamos los datos de facturación para dar de alta la primera
        campaña.
      </div>
      <FormProvider {...methods} autoComplete={false}>
        <div className="CompanyInfo__form">
          <div className="CompanyInfo__form__row">
            <FormTextInput
              control={control}
              name="nif"
              label="NIF"
              required={true}
            />
            <FormTextInput
              control={control}
              name="company_name"
              label="Razón social"
              required={true}
            />
          </div>
          <div className="CompanyInfo__form__row">
            <FormTextInput
              control={control}
              name="street"
              label="Calle"
              required={true}
            />
            <div className="CompanyInfo__form__row__street-details">
              <FormTextInput
                control={control}
                name="number"
                label="Número"
                required={true}
              />
              <FormTextInput control={control} name="floor" label="Planta" />
              <FormTextInput
                control={control}
                name="apartment"
                label="Puerta"
              />
            </div>
          </div>
          <div className="CompanyInfo__form__row">
            <FormTextInput
              control={control}
              name="city"
              label="Ciudad"
              required={true}
            />
            <FormTextInput
              control={control}
              name="zip_code"
              label="Código postal"
              required={true}
            />
            <FormTextInput
              control={control}
              name="province"
              label="Provincia"
              required={true}
            />
          </div>
        </div>
        <div className="CompanyInfo__actions">
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || !isDirty || isSubmitting}
          >
            Siguiente
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};

export default CompanyInfo;
