import * as yup from "yup";

export function buildValidationSchema() {
  return yup.object().shape({
    influencer_prize: yup
      .string()
      .label("Premio por story")
      .required("Campo requerido"),
    name: yup
      .string()
      .label("Nombre de la campaña")
      .required("Campo requerido"),
  });
}
