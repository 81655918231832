import useAuth from "../../models/auth/useAuth";
import Unauthorized from "../Unauthorized";

const AdminRoute = ({ children }) => {
  const {
    user: { brand_worker },
  } = useAuth();

  if (brand_worker && brand_worker.is_admin) return children;

  return <Unauthorized />;
};

export default AdminRoute;
