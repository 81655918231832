import useFetch from "../../utils/useFetch";

const useInvitations = () => {
  const { post } = useFetch();

  const endpoint = "invitations";

  const sendInvitation = async (body) => {
    await post({
      endpoint: `${endpoint}/brand-create`,
      body,
    });
  };

  return {
    sendInvitation,
  };
};

export default useInvitations;
