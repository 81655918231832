import { useSelector, useDispatch } from "react-redux";
import useFetch from "../../utils/useFetch";
import { setList } from "./store";

const useWorkers = () => {
  const { list } = useSelector((state) => state.workers);
  const dispatch = useDispatch();
  const { get, post, del } = useFetch();

  const endpoint = "brand-workers";

  const fetchList = async () => {
    const response = await get({
      endpoint: `${endpoint}`,
      isPrivate: true,
    });
    dispatch(setList(response));
  };

  const deleteWorker = async (id) => {
    await del({
      endpoint: `${endpoint}`,
      id,
    });
    fetchList();
  };

  const setIsAdmin = async (id, isAdmin) => {
    await post({
      endpoint: `${endpoint}/${id}/set-admin`,
      body: { is_admin: isAdmin },
    });
    fetchList();
  };

  const validateInvitation = async (code) => {
    await post({
      endpoint: `${endpoint}/validate-invitation`,
      body: { invitation_code: code },
    });
  };

  return {
    workers: list,
    fetchWorkers: fetchList,
    deleteWorker,
    setIsAdmin,
    validateInvitation,
  };
};

export default useWorkers;
