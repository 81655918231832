import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useSearchParams, Navigate } from "react-router-dom";
import useWorkers from "../../models/workers/useWorkers";
import useAuth from "../../models/auth/useAuth";

import "./styles.scss";

const Invitations = () => {
  const [searchParams] = useSearchParams();
  const { me } = useAuth();
  const invitationCode = searchParams.get("code");
  const { validateInvitation } = useWorkers();
  const [state, setState] = useState("loading");

  const initInvitation = async () => {
    try {
      await validateInvitation(invitationCode);
      await me();
      setState("OK");
    } catch (e) {
      setState("KO");
    }
  };

  useEffect(() => {
    invitationCode && initInvitation();
  }, []);

  if (!invitationCode) {
    return (
      <div className="Invitations">
        <h1>Código incorrecto o ausente</h1>
        <div>Por favor, vuelva a acceder desde el enlace del email.</div>
      </div>
    );
  }

  const renderComp = () => {
    switch (state) {
      case "loading":
        return (
          <>
            <h1>Validando invitación</h1>
            <div>Por favor, espera unos momentos.</div>
          </>
        );
      case "OK":
        return <Navigate to="/admin" />;
      case "KO":
        return (
          <>
            <h1>Ha ocurrido un error</h1>
            <div>
              No hemos podido procesar su solicitud. Por favor, póngase en
              contacto con su administrador.
            </div>
          </>
        );
    }
  };

  return <div className="Invitations">{renderComp()}</div>;
};

export default Invitations;
