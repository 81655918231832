import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import banner from "./images/banner.png";

import "./styles.scss";

const CampaignsEmptyState = ({ onClick }) => {
  return (
    <div className="CampaignsEmptyState">
      <div className="CampaignsEmptyState__box">
        <div className="CampaignsEmptyState__main">
          <div className="CampaignsEmptyState__title">
            Da de alta tu primera <span>campaña en Wubalu.</span>
          </div>
          <div className="CampaignsEmptyState__subtitle">
            Empieza ya a poner de moda tus marcas generando publicidad en redes
            sociales y captando nuevos clientes.
          </div>
          <Link to="./create">
            <Button
              className="CampaignsEmptyState__submit"
              variant="contained"
              onClick={onClick}
            >
              Añadir Campaña
            </Button>
          </Link>
        </div>
        <div className="CampaignsEmptyState__banner">
          <img src={banner} />
        </div>
      </div>
    </div>
  );
};

export default CampaignsEmptyState;
