const menuItems = [
  {
    path: "/admin",
    name: "Campañas",
    admin: true,
  },
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    admin: true,
  },
  {
    path: "/admin/workers",
    name: "Trabajadores",
    admin: true,
  },
  {
    path: "/admin/stories",
    name: "Stories",
    admin: true,
  },
];

export default menuItems;
