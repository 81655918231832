import { useSelector, useDispatch } from "react-redux";
import useFetch from "../../utils/useFetch";

const useAccounts = () => {
  const dispatch = useDispatch();
  const { get, post } = useFetch();

  const endpoint = "brands";

  const createAccount = async (data) => {
    return await post({
      endpoint,
      body: data,
    });
  };

  // const fetchAnalytics = async (restaurant) => {
  //   const response = await get({
  //     endpoint: `${endpoint}/statistics`,
  //     params: { restaurant },
  //     isPrivate: true,
  //   });
  //   response && dispatch(setAnalytics(response));
  // };

  const getRestaurantList = async () => {
    return await get({
      endpoint: `${endpoint}/restaurants`,
      isPrivate: true,
    });
  };

  const updateCompanyInfo = async (data) => {
    return await post({
      endpoint: `${endpoint}/update-company-info`,
      body: data,
    });
  };

  const createSetupIntent = async () => {
    return await post({
      endpoint: `${endpoint}/create-setup-intent`,
      body: {},
    });
  };

  const setPaymentMethod = async (data) => {
    return await post({
      endpoint: `${endpoint}/set-payment-method`,
      body: data,
    });
  };

  const getStripePortalUrl = async () => {
    return await get({
      endpoint: `${endpoint}/stripe-portal-url`,
      isPrivate: true,
    });
  };

  return {
    // fetchAnalytics,
    createAccount,
    getRestaurantList,
    updateCompanyInfo,
    createSetupIntent,
    setPaymentMethod,
    getStripePortalUrl,
  };
};

export default useAccounts;
